import AlertMessage from '@/app/components/AlertMessage'
import IconToggleLabel from '@/authenticated/components/IconToggleLabel'
import { sanitizeHtmlContent } from '@/utils/components'
import { Vehicle } from '@/types'
import { useViewModel } from '../../../devices/vehicle/DepartureAndPlanCard'
import { ConfigurableText } from '@/app/components'

type Props = {
  vehicle: Vehicle
}

const AllowChargeControlToggle = ({ vehicle }: Props) => {
  const viewModel = useViewModel(vehicle)
  const {
    chargeSchedule,
    overrideValue,
    onChargeScheduleChange,
    canVehicleCharge,
    utilityAlertSubtitle,
  } = viewModel

  let alertMessage = (
    <AlertMessage
      show={!canVehicleCharge}
      variant="info"
      style={{ marginTop: 10 }}
    >
      This vehicle does not currently support third-party charge management.
    </AlertMessage>
  )
  if (utilityAlertSubtitle && canVehicleCharge) {
    alertMessage = (
      <AlertMessage show variant="info" style={{ marginTop: 10 }}>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtmlContent(utilityAlertSubtitle),
          }}
        />
      </AlertMessage>
    )
  }

  const overrideValueWasProvided =
    overrideValue !== undefined && overrideValue !== null
  const overriddenCheckedValue = !canVehicleCharge ? false : overrideValue

  return (
    <>
      <IconToggleLabel
        id="allow-charge-control-toggle"
        title=""
        shouldDebounce={false}
        model={chargeSchedule}
        disabled={!canVehicleCharge || overrideValueWasProvided}
        field="allow_charging_control"
        onChange={onChargeScheduleChange}
        checked={overriddenCheckedValue}
      >
        <ConfigurableText variant="subheader">
          Allow Optiwatt to Manage Charging
        </ConfigurableText>
      </IconToggleLabel>
      {alertMessage}
    </>
  )
}

export default AllowChargeControlToggle
