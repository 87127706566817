import { useHistory, useParams } from 'react-router-dom'
import { Card, Icon } from '@/components'
import { ConfigurableButton, ConfigurableText } from '@/app/components'
import { useAppSelector } from '@/hooks'
import Sheet from '@/components/Sheet/Sheet'
import AllowChargeControlToggle from './AllowChargeControlToggle'
import ShareComponent from '@/app/pages/rewards/tabs/components/shareComponent'
import { useNavigation } from '@/app/hooks'
import { localGridDetails } from './localGridDetails'
import { capitalize } from '@/utils/stringUtilities'
import { DeviceType } from '@/app/components/DeviceSelectionGallery/DeviceSelectionGallery.types'
import { utilityProgramCollection } from '@/reducers/utilityPrograms'
import { utilityProgramEnrollmentCollection } from '@/reducers/utilityProgramEnrollments'
import { getVehicleDisplayModel } from '@/utils/CarUtils'

export default function LocalGridSupportDetails() {
  const history = useHistory()
  const navigate = useNavigation()

  type PathParams = {
    localGridId: string
    deviceType: DeviceType | undefined
    deviceId: string | undefined
  }
  const { localGridId, deviceId } = useParams<PathParams>()
  const localGridDetail = useAppSelector((state) => {
    return localGridDetails.find(
      (localGridProgram) =>
        utilityProgramCollection.selectors.selectById(state, localGridId)
          ?.name === localGridProgram.name ||
        utilityProgramEnrollmentCollection.selectors
          .selectAll(state)
          ?.some(
            (enrollments) =>
              enrollments.utility_program.name === localGridProgram.name,
          ),
    )
  })

  const selectedVehicle = useAppSelector((state) => {
    if (!deviceId) return null
    return state.vehicles.vehicles?.find(
      (vehicle) => vehicle.id === parseInt(deviceId),
    )
  })

  if (!selectedVehicle || !localGridDetail) return null

  return (
    <div className="w-full flex justify-center sm:p-4">
      <div className="w-fit max-w-md">
        <Card
          title="Massachusetts Clean Peak Program Rebate"
          className="w-full max-sm:rounded-none"
        >
          <div className="flex flex-row items-start">
            <ConfigurableButton id="LocalGridBackButton" variant="icon">
              <Icon name="ArrowLeft" onClick={history.goBack} />
            </ConfigurableButton>
            <div className="flex flex-col flex-1 p-4 gap-2">
              <ConfigurableText variant="h3">
                {localGridDetail.title}
              </ConfigurableText>
              <ConfigurableText variant="body4">
                {localGridDetail.description}
              </ConfigurableText>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full rounded-lg border-2 border-solid border-themed-base-100 h-[295px]">
            <div className="flex items-center h-24 w-full justify-around">
              <div className="flex flex-col w-1/2 pl-5 py-5 h-full justify-center">
                <ConfigurableText variant="subheader">
                  {selectedVehicle?.car.display_name ||
                    capitalize(selectedVehicle?.car.car_model?.make ?? '')}
                </ConfigurableText>
                <ConfigurableText variant="body2">
                  {`${
                    selectedVehicle?.car.car_model?.year
                  } ${getVehicleDisplayModel(selectedVehicle)}`}
                </ConfigurableText>
              </div>
              <div className="h-full w-1/2 flex justify-end pr-5">
                <img
                  className="max-w-full max-h-full object-contain"
                  src={`/dashboard/img/car_models${selectedVehicle?.car.image_url}`}
                  alt="Vehicle"
                />
              </div>
            </div>
            <div className="w-full px-5">
              <div className="bg-[#E5FBEC] rounded-lg h-28 w-full flex flex-col items-center gap-2 justify-center p-5">
                <ConfigurableText
                  variant="body1"
                  className="text-center text-[#00922B]"
                >
                  Earn up to
                </ConfigurableText>
                <div className="flex flex-row items-end">
                  <ConfigurableText
                    variant="h1"
                    className="text-center text-[#00922B]"
                  >
                    {`$${localGridDetail.earnings}`}
                  </ConfigurableText>
                  <ConfigurableText
                    variant="body1"
                    className="text-center text-[#00922B] py-1 font-light"
                  >
                    {' / year'}
                  </ConfigurableText>
                </div>
              </div>
            </div>
            <div className="w-full p-5">
              <ConfigurableButton
                id="viewEarningsButtons"
                variant="primary-subtle"
                className="rounded-lg h-[42px]"
                onClick={() => navigate.push('/rewards?tab=Activity')}
              >
                View Earnings
              </ConfigurableButton>
            </div>
          </div>
          <div className="flex items-center gap-3 pt-8">
            <ConfigurableText variant="h3">
              How to boost your earnings
            </ConfigurableText>
            <Sheet color="#ECF1FF">
              <ConfigurableText className="text-[#426FFB]" variant="body3">
                New
              </ConfigurableText>
            </Sheet>
          </div>
          <ConfigurableText variant="body4">
            {`Earn up to $${localGridDetail.earnings} a year`}
          </ConfigurableText>
          <div className="flex flex-col w-full items-start pt-[24px] gap-[24px]">
            <div className="w-full flex items-start gap-4">
              <Sheet
                color="#ECF1FF"
                className="aspect-square !flex justify-center items-center"
              >
                <ConfigurableText
                  className="text-[#426FFB] !m-0"
                  variant="body2"
                >
                  1
                </ConfigurableText>
              </Sheet>
              <div className="w-full">
                <AllowChargeControlToggle vehicle={selectedVehicle} />
                <ConfigurableText variant="body4" className="px-1.5 pt-2.5">
                  Optiwatt will help you save the most money based off your
                  habits
                </ConfigurableText>
              </div>
            </div>
            <div className="w-full flex items-start gap-4">
              <Sheet
                color="#ECF1FF"
                className="aspect-square !flex justify-center items-center"
              >
                <ConfigurableText
                  className="text-[#426FFB] !m-0"
                  variant="body2"
                >
                  2
                </ConfigurableText>
              </Sheet>
              <div className="w-full">
                <ConfigurableText variant="subheader" className="px-1.5">
                  Invite a friend and earn a lifetime bonus
                </ConfigurableText>
                <ConfigurableText variant="body4" className="px-1.5 pt-2.5">
                  {localGridDetail.referralText}
                </ConfigurableText>
                <div className="pt-5">
                  <ShareComponent
                    buttonProps={{
                      variant: 'primary-subtle',
                      className: 'rounded-lg h-[42px]',
                    }}
                  >
                    <ConfigurableText
                      variant="body2"
                      className="text-themed-primary"
                    >
                      Invite a Friend
                    </ConfigurableText>
                  </ShareComponent>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}
